<template>
  <div class="px-4">
    <v-card class="d-flex align-center rounded-lg ma-4 pa-2" flat>
      <v-row>
        <v-col class="py-0 my-0" cols="12" md="6" sm="12">
          <v-switch
            color="success"
            v-model="switch1"
            :label="$t('mail.ViewArchivedMail')"
            class="mx-1"
            @change="getMails"
          ></v-switch>
        </v-col>
        <v-col
          :class="$i18n.locale == 'ar' ? 'text-left mt-1' : 'text-right mt-1'"
          cols="12"
          md="6"
          sm="12"
        >
          <v-btn
            color="primary"
            outlined
            class="mx-1"
            :loading="loadingMakeAsRead"
            @click="makeAsRead"
            :disabled="!rowKeysSelected.length>0"
          >
            <v-icon left> mdi-flag-variant </v-icon>
            {{ $t("mail.SelectAsRead") }}
          </v-btn>

          <v-btn
            color="success"
            outlined
            class="mx-1"
            :loading="loadingArchiveMail"
            @click="archiveMail"
            v-if="!switch1"
            :disabled="!rowKeysSelected.length>0"

          >
            <v-icon left> mdi-archive-arrow-down </v-icon>
            {{ $t("mail.ArchiveMail") }}
          </v-btn>
          <v-btn
            color="success"
            outlined
            class="mx-1"
            :loading="loadingArchiveMail"
            @click="unArchiveMail"
            :disabled="!rowKeysSelected.length>0"

            v-else
          >
            <v-icon left> mdi-archive-arrow-up </v-icon>
            {{ $t("mail.CancelArchivingMail") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="px-4">
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('writeWhatYouSearch')"
          outlined
          dense
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card class="d-flex align-center rounded-lg mx-4 pa-2 pb-0" flat>
          <v-card-text>
            <v-data-table
              v-model="rowKeysSelected"
              :headers="_headers"
              :items="mails"
              :search="search"
              item-key="guid"
              show-select
              width="100%"
              :loading="loadingData"
            >
              <template v-slot:[`item.isRead`]="{ item }">
                <v-tooltip top v-if="!item.isRead">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="pink lighten-1" v-bind="attrs" v-on="on">mdi-email</v-icon>
                  </template>
                  <span>{{ $t("mail.notReadable") }}</span>
                </v-tooltip>
                <v-tooltip top v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">mdi-email-open</v-icon>
                  </template>
                  <span>{{ $t("mail.Read") }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.attachedFileUrl`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.attachedFileUrl"
                      icon
                      color="green"
                      @click="installFile(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("mail.DownloadTheAttachment") }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.datex`]="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p v-bind="attrs" v-on="on">
                        {{ item.datex2 }}
                      </p>
                    </template>
                    <span>{{ item.datex }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.fullSectionPath`]="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p v-bind="attrs" v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                      </p>
                    </template>
                    <span>{{ item.fullSectionPath }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" @click="showMail(item)" v-bind="attrs" v-on="on">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Preview") }}</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="" @click="replyDialog(item)" v-bind="attrs" v-on="on">
                        <v-icon>mdi-reply</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("mail.reply") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ReplyMail ref="ReplyMailComponent" v-on:send-result-values="getMails"></ReplyMail>

  </div>
</template>

<script>
import moment from "moment";
import ReplyMail from "../../components/mails/replyMail.vue";

export default {
  components: {
    ReplyMail
  },
  data() {
    return {
      search: "",
      loadingData: false,
      loadingMakeAsRead: false,
      loadingArchiveMail: false,
      dataGridRefName: "dataGrid",
      switch1: 0,
      pageSizes: [5, 10, 20],
      rtlEnabled: true,
      allMode: "allPages",
      checkBoxesMode: "onClick",
      mails: [],
      rowKeysSelected: [],
      headers: [
        {
          text: this.$t("mail.status"),
          value: "isRead",
          show: true
        },
        {
          text: this.$t("mail.subjectName"),
          value: "subjectName",
          show: true
        },
        {
          text: this.$t("mail.from"),
          value: "fromName",
          show: true
        },
        {
          text: this.$t("mail.title"),
          value: "title",
          show: true
        },

        {
          text: this.$t("mail.Attachments"),
          value: "attachedFileUrl",
          show: true
        },

        {
          text: this.$t("date"),
          value: "datex",
          show: true
        },
        {
          text: this.$t("mail.Section"),
          value: "fullSectionPath",
          show: this.isInRole(2)
        },
        {
          text: this.$t("mail.actions"),
          value: "actions",
          show: true
        }
      ]
    };
  },
  computed: {
    _headers() {
      return this.headers.filter(x => x.show);
    }
  },
  methods: {
    replyDialog(data) {
      // console.log(data.guid);
      this.$refs.ReplyMailComponent.setValue(data.guid);
    },

    archiveMail() {
      if (!this.rowKeysSelected || this.rowKeysSelected.length == 0) {
            return;
      }
      this.loadingArchiveMail = true;
      const data = {
        archiving: true,
        mailType: 0,
        mailGuids: this.rowKeysSelected.map(m => m.guid)
      };
      this.axios
        .post("Mails/Archiving", data)
        .then(() => {
          this.getMails();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.loadingArchiveMail = false;
        });
    },
    unArchiveMail() {
        if (!this.rowKeysSelected || this.rowKeysSelected.length == 0) {
            return;
      }
      this.loadingArchiveMail = true;

      const data = {
        archiving: false,
        mailType: 0,
        mailGuids: this.rowKeysSelected.map(m => m.guid)
      };
      this.axios
        .post("Mails/Archiving", data)
        .then(() => {
          this.getMails();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.loadingArchiveMail = false;
        });
    },
    makeAsRead() {
        if (!this.rowKeysSelected || this.rowKeysSelected.length == 0) {
            return;
      }
      this.loadingMakeAsRead = true;

      this.axios
        .post(
          "Mails/MarkAsRead",
          this.rowKeysSelected.map(m => m.guid)
        )
        .then(() => {
          this.getMails();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close")
          });
        })
        .finally(() => {
          this.loadingMakeAsRead = false;
        });
    },
    showMail(data) {
      if (!data.isRead) {
        this.axios
          .post("Mails/MarkAsRead", [data.guid])
          .then(() => {})
          .catch(() => {})
          .finally(() => {});
      }
      this.$router.push({
        path: `showMail=${data.guid}&0`,
        params: { id: data.guid, type: 0 }
      });
    },
    installFile(data) {
      window.open(`${data.attachedFileUrl}`);
    },
    getMails() {
      this.loadingData = true;
      const archiving = this.switch1 ? 1 : 0;
      this.axios
        .get(`Mails/Get?mailType=0&archiving=${archiving}`)
        .then(res => {
          this.rowKeysSelected = [];
          this.mails = res.data.data;
          this.mails.forEach(e => {
            e.datex2 = moment(e.datex).fromNow();
            e.datex = moment(e.datex).format("YYYY/MM/DD - h:mm a ");
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loadingData = false;
        });
    }
  },
  created() {
    this.getMails();
  }
};
</script>
<style lang="scss">
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
